@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&family=Fasthand&family=Roboto+Condensed:wght@400;700&family=Caveat&display=swap');

:root{
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
}

body {
  margin: 0;
}

#root{
  max-width: 100vw;
  height: 100vh;

  display: grid;
  grid-template-rows: 1fr;
}

.loading-container{
  width: 100vw;
  height: 100%;
  position: absolute;
  z-index: 3;
  background-color: #dfd3c389;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-box{
  position: relative;
  background-color: #0d172a;
  border: 2px solid #0d172a;
  height: 8rem;
  width: 8rem;
  outline: none;
  overflow: hidden;
}

.loading-box::before{
  content: "Y";
  font-family: 'Ms Madi', cursive;
  color: #d8cfcf;
  font-size: 5rem;

  display: flex;
  align-items: center;
  justify-content: center; 
}

.loading-box::after{
  content: "";
  position: absolute;
  bottom: -50%;
  left: -50%;
  height: 200%;
  width: 200% ;
  background-color: #dfd3c389;
  border-radius: 45%;
  animation: spin 5s ease-in-out infinite;
}

.login-page{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.login-divider{
  height: 50vh;
  width: 2px;
  background-color: gray;
}

.login-right{
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.login-form{
  width: 100%;
  box-sizing: border-box;
  padding: 20px 30px;
  background-color: #c06c84;
  border-radius: 20px;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.logo{
  font-family: 'Ms Madi', cursive;
}

input{

  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  vertical-align: baseline;
  font-weight: 400;
  line-height: 1.29;
  letter-spacing: .16px;
  border-radius: 5px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  background-color: #f4f4f4;
  padding: 0 16px;
  color: #161616;
  transition: background-color 70ms cubic-bezier(.2,0,.38,.9),outline 70ms cubic-bezier(.2,0,.38,.9);  
}

input:focus{
  outline: 2px solid #596e79;
  outline-offset: -2px;
}

button {
  background-color: #fff;
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #0d172a;
  cursor: pointer;
  display: inline-block;
  font-family: "Basier circle",-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #0d172a solid;
  text-decoration-thickness: auto;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  align-self: center;
}

button:hover {
  background-color: #1e293b;
  color: #fff;
}

select{
  font-family: inherit;
  border: none;
  border-radius: 5px;
  background-color: transparent;
  background-color: #d8cfcf;
  border-bottom: 1px solid #8d8d8d;
  padding: 0 5px;
}

select:focus{
  outline: 2px solid #596e79;
  outline-offset: -2px;
}

.auth-switch{
  font-size: .8rem;
  color: #fcf4d9;
}

.auth-switch span{
  color: #596e79;
  cursor: pointer;
}

.error-message{
  padding: 10px 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-left: 5px solid #247291;
  border-radius: 5px;
  color: #b5592a;

  position: absolute;
  top: 10px;
  right: 10px;
}

.main-page{
  display: grid;
  grid-template-rows: auto 1fr;
}

header{
  padding: 10px 30px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.username{
  padding: 20px;
  border-radius: 5px;
  color: #596e79;
  font-size: 1.1rem;
  font-weight: 700;

  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.account-menu{
  padding: 20px;
  background-color: #596e79;
  color: #d8cfcf;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  z-index: 3;
  position: absolute;
  top:90px;
  right: 30px;
  font-size: .9rem;

  display: grid;
  gap: 10px;
}

.account-menu div{
  cursor: pointer;
  text-align: end;
}

.del-account{
  font-size: .8rem;
  color: #8cbeaa;
}

.overview{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Fasthand', cursive;
  font-size: 3rem;
  background-color: #FCFEFB;
  color: #8d8d8d;
}

.overview>div{
  display: flex;
  flex-direction: column;
}

.overview img{
  height: 60%;
}

.dashboard{
  background-color: #dfd3c3;
  padding: 20px;
  position: relative;
}

.empty-dashboard{
  display: flex;
  justify-content: center;
  grid-column: 1/-1;
  font-size: 1.5rem;
}

.dashboard-options{
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.add-button{
  padding: 12px;
  background-color:#596e79;
  border-radius: 50%;
  color:#f0ece2;
  font-size: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.search{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.search input{
  max-width: 250px;
  height: 30px;
}

.dashboard-main{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.album-card{
  background-color: white;
  height: 300px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.album-card img{
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.album-card-date{
  position: absolute;
  bottom: 5%;
  right: 5%;
  font-family: 'Caveat', cursive;
  font-size: 1.2rem;
  color: #596e79;
}

.star-rating{
  font-size: 1.2rem;
  color: #ececec;
  display: flex;
}

.filled-star{
  color: #c06c84;
}

.popup-container{
  width: 100%;
  min-height: 100%;
  position: absolute;
  background-color: #f0ece2d3;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.popup-container.confirm{
  z-index: 2;
}

.popup{
  width: 95%;
  height: max-content;
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  margin: 10px 0;
  overflow: auto;

  display: flex;
  flex-direction: column;
}

.confirm-popup{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #8d8d8d;
  padding: 20px;
  border-radius: 5px;
  font-size: 1.2rem;

  display: grid;
  justify-items: center;
  gap: 20px;
}

.confirm-popup div{
  display: flex;
  gap: 10px;
}

.album-input{
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 10px;
  align-items: center;
}

.album-input .star-rating{
  cursor: pointer;
}

.create-general-section{
  display: grid;
  grid-template-columns: 1fr 1fr;

}

.general-details{
  display: grid;
  gap: 10px;
}

.create-cover{
  height: 150px;
  justify-self: center;
}

.create-cover img{
  height: 100%;
}

.create-dish-section{
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.create-dish-section button{
  align-self: flex-start;
}

.create-end-buttons{
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px;
}

.dish-count{
  font-size: 1.2rem;
  color: #247291;
  font-weight: 700;
}

.fa-square-minus{
  color: #596e79;
  cursor: pointer;
  font-size: 1.2rem;
}

.dish-details{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.dish-details .album-input{
  grid-template-columns: auto 1fr;
}


.dish-details button{
  background-color: #1e293b;
  color: #fff;
}

.dish-details button:hover{
  color: #1e293b;
  background-color: #fff;
}

.dish-rating{
  display: flex;
  align-items: center;
}

.dish-rating, .dish-rating select{
  font-size: 1.2rem;
}

.thumbnail{
  position: relative;
  margin-left: 20px;
  height: 60px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.thumbnail img{
  height: 100%;
  width: 100%;
}

.selected{
  border: 2px solid #c06c84;
}

.create-others-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.create-others-section textarea{
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1rem;
  width: 70%;
  height: 100px;
  padding: 10px;
}

.add-other-images{
  display: flex;
}

.album-actions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.album-actions svg{
  padding: 8px;
  margin: 0 5px; 
  cursor: pointer;
  font-size: 1.2rem;
}

.back-button{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #596e79;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.album-view{
  padding: 0 20px;
}

.album-location, .album-date{
  display: flex;
  align-items: center;
  gap: 5px;
}

.album-location{
  cursor: pointer;
  color: #247291; 
}

.album-title{
  font-size: 1.4rem;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  align-items: center;
}

.album-body{
  width: 100%;

  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
}

.dishlist-container{
  width: 100%;
  padding: 10px 0;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
}

.dishlist-item{
  height: max-content;
}

.dishlist-title{
  font-family: Caveat;
  text-align: end;
  font-size: 1.3rem;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

.dishlist-image{
  width: 100%;
  max-height: 80%;
  object-fit: cover;
}

.notes{
  background-color: #f8da5b;
  padding: 10px;
  overflow: auto;
}

.notes p{
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0;
}

.notes div{
  padding: 10px;
  font-family: Caveat;
  font-size: 1.2rem;
  white-space: pre-wrap;
}

.carousel{
  grid-column: 1/3;

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.carousel-main{
  position: relative;
  height: 400px;

  display: flex;
  gap: 5px;
}

.carousel-polaroid{
  height: 100%;
  box-sizing: border-box;
  padding: 10px 10px 50px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.carousel-polaroid img{
  height: 100%;
  max-width: 60vw;
  object-fit: cover;
}

.arrow{
  font-size: 1.5rem;
  cursor: pointer;
  color:#596e79;

  display: flex;
  align-items: center;
}

.arrow svg{
  padding: 0 5px;
}

@media (min-width: 768px) {
  button {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}

@media (hover:hover) {
  .restaurant-card:hover{
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  .add-button:hover{
      transform: scale(1.05);
      background-color: #c06c84;
  }
  .back-button:hover{
    background-color: #596e79;
    color: #f0ece2;
  }
  .fa-trash:hover{
    color: crimson;
  }
  .fa-pen-to-square:hover{
    color: darkgoldenrod;
  }
  .album-location span:hover{
    text-decoration: underline;
  }
  .username:hover{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-size: 1.05rem;
  }
}

@keyframes spin {
  0% {
    transform: translateY(75%) rotate(0deg);
  }
  100% {
    transform: translateY(20%) rotate(600deg);
  }
}

@media (max-width: 768px){
  :root{
    font-size: 14px;
  }
  header{
    padding-right: 10px;
  }
  .overview{
    justify-content: flex-start;
    padding: 40px 20px;
    max-width: 100vw;
    box-sizing: border-box;
  }
  .create-general-section{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 10px;
  }
  .create-cover{
    grid-row: 1;
  }
  .album-input{
    grid-template-columns: 100px 1fr;
  }
  .create-dish-section{
    padding: 25px 0;
  }
  .create-dish-section label{
    font-size: .8rem;
  }
  .dish-details{
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .dish-details span+div{
    grid-column: 1/4;
    justify-self: center;
  }
  .dish-rating, .dish-rating select{
    font-size: 1rem;
  }
  .thumbnail{
    height: 40px;
    max-width: 40px;
  }
  .create-dish-section button{
    align-self: center;
  }
  .album-view{
    padding: 0;
  }
  .album-body{
    grid-template-columns: 1fr;
  }
  .carousel{
    grid-column: auto;
  }
  .login-page{
    flex-direction: column;
  }
  .login-divider{
    width: 60vw;
    height: 2px;
  }
}